.pauseTransaction {
  margin-right: 1em;
}

.bntContent {
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5em;
  }
}