.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}

.medias {
  display: grid;
  grid-gap: 5px;
  justify-content: space-evenly;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }
  
  & > div {
    margin-left: auto;
    margin-right: auto;
  }
}