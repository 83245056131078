@import "colors.module";

.sideBar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  height: 100vh;
  width: 250px;
  background: $gray-light-bg;
  padding: 20px;

  &__items {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__navItem {
    display: flex;

    > div {
      max-width: 80%;
      overflow-wrap: anywhere;
    }
  }

  &__logoutItem {
    margin-top: auto !important;
  }

  @media (max-width: 768px) {
    overflow: visible;
    height: 120px;
    padding-top: 5px;
    width: 100%;
    position: relative;

    &__items {
      overflow-y: hidden;
      overflow-x: auto;
      overflow-scrolling: auto;
      flex-direction: row;
      justify-content: space-between;
      height: min-content;
      margin-top: 0;

      > div {
        height: min-content !important;
        margin-bottom: 10px !important;
      }
    }


    &__navItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 6px;
      font-size: 10px !important;

      svg {
        width: 30px;
      }

      div {
        max-width: 100%;
        overflow-wrap: anywhere;
        margin-left: 0 !important;
        white-space: nowrap;
      }
    }

    &__logoutItem {
      margin-top: 1rem !important;
    }

    &__language {
      position: absolute;
      bottom: 5px;
      right: 25px;
      z-index: 100;
    }
  }

  @media (max-width: 350px) {
    &__navItem {
      margin-right: 5px;
      font-size: 8px !important;

      svg {
        width: 25px;
      }
    }
  }
}