@font-face {
  font-family: CircularStd-Book;
  src: url(../fonts/CircularStd-Book.otf);
}

@font-face {
  font-family: CircularStd-Black;
  src: url(../fonts/CircularStd-Black.otf);
}

@font-face {
  font-family: CircularStd-Bold;
  src: url(../fonts/CircularStd-Bold.otf);
}

@font-face {
  font-family: CircularStd-Medium;
  src: url(../fonts/CircularStd-Medium.otf);
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../fonts/Montserrat-SemiBold.ttf);
}

body {
  margin: 0;
  font-family: Montserrat-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-block {
  border-radius: 8px 8px 9px 9px;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 16px;
  padding: 11px 0px;
}

*:focus {
  outline: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.keep-line-breaks {
  white-space: pre-line;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  color: #004bb4;
}

.color-blue-tripartie {
  color: #004bb4 !important;
}

.clickable {
  cursor: pointer;
}

.btn-loading {
  position: relative;
}

.btn-loading .btn-content {
  visibility: hidden;
}

.btn-loading .btn-spinner {
  visibility: visible;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.data-list {
  width: 100%;
  max-width: 100%;
}

.table-navigation {
  display: flex;
  max-width: calc(100vw - 20px);
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.data-list td {
  padding: 0.75rem;
}

.data-list .link {
  display: block;
  color: initial !important;
  text-decoration: none !important;
}

.data-card {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  overflow: hidden;
}

.card-content {
  overflow-wrap: anywhere;
}

.full-width-dropdown .dropdown-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.full-width-dropdown .dropdown-menu {
  min-width: 100%;
}

@media (max-width: 768px) {
  .table-navigation {
    flex-direction: column-reverse;
  }

  .table-navigation div:first-child {
    margin-bottom: 10px;
  }

  .data-list {
    max-width: calc(100vw - 20px);
  }

  .card-content > div {
    flex-direction: column;
  }

  .card-content > div > div,
  .card-content > div > span {
    display: inline-block;
    overflow-wrap: anywhere;
    max-width: calc(100vw - 60px);
  }

  .recharts-legend-wrapper {
    right: -20px !important;
  }

}

.button-link {
  background: none;
  border: none;
}

.modal {
  padding-left: 0 !important;
}