.language-selector {
  &.small * {
    font-size: 12px;
    color: #8a93a8;
  }

  .dropdown-menu {
    min-width: 0;
  }

  .dropdown-item {
    padding: 0.25em 1em;
  }

  &__flag {
    display: block;
    height: 1.2em;
    width: 1.2em;
    margin-right: 10px;
  }

  &__label {
    display: block;
  }

  &__arrow {
    display: block;
    height: 10px;
    width: 10px;
    margin-left: 8px;
    stroke: #8a93a8;
  }
}
