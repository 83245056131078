@import "colors.module";

.cropImageModal {
  padding-top: 30px;

  &__title {
    font-size: 22px;
    font-family: Montserrat-Bold;
  }

  &__cropper {
    margin: 10px 0 20px 0;
    width: 100%;
  }

  &__saveBtn {
    margin-left: 10px;
    background: none;
    color: var(--primary);
    border: 1px solid var(--primary);
  }
}