.queryForm {
  display: flex;
  margin-bottom: 20px;
  max-width: calc(100vw - 20px);

  > div {
    display: flex;
    width: min-content;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-right: 0;

    input {
      margin-bottom: 10px;
    }

    > div {
      display: flex;
      width: 100%;
    }

    button {
      width: 100%;

      &:first-child {
        margin-left: 0 !important;
      }
    }

  }
}