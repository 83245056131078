@import "colors.module";

.app {
  padding-left: 250px;
  min-height: 100vh;
  height: 100%;

  &__content {
    padding: 10px 30px;
  }

  @media (max-width: 768px) {
    padding-left: 0;

    &__content {
      padding: 10px;
    }
  }

}